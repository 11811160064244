$max-width:1280px;
$min-width:1024px;

$base-font-color:#333;
$header-color: #ECEFF1;

$media-owner__color--thick:#3caf50;
$media-owner__color--medium:#B3E5FC;
$media-owner__color--pale:#E1F5FE;

$common__color--thick:#28a0dc;
$common__color--medium:#B3E5FC;
$common__color--pale:#E1F5FE;

$advertiser__color--thick:#ff8228;
$advertiser__color--medium:#ffe18c;
$advertiser__color--pale:#fff5dc;


@mixin after_icon($icon, $font-size, $color, $width, $height, $left, $right) {
  &:after {
    position: absolute;
    top:0;
    bottom: 0;
    width:$width;
    height:$height;
    left:$left;
    right:$right;
    margin: auto;
    font-family: 'Material Icons';
    content: $icon;
    font-size: $font-size;
    line-height:$height;
    color: $color;
    font-weight: normal;
  }
}

@mixin background_oblique_line($color1, $color2){
  background: repeating-linear-gradient(-55deg, $color1, $color1 1px,$color2 1px, $color2 6px);
}

/*
* Noto Sans JP (japanese) http://www.google.com/fonts/earlyaccess
 */
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 100;
  src: url(/fonts/NotoSansJP-Thin.woff2) format('woff2'),
  url(/fonts/NotoSansJP-Thin.woff) format('woff'),
  url(/fonts/NotoSansJP-Thin.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 300;
  src: url(/fonts/NotoSansJP-Light.woff2) format('woff2'),
  url(/fonts/NotoSansJP-Light.woff) format('woff'),
  url(/fonts/NotoSansJP-Light.otf) format('opentype');
}

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/NotoSansJP-Regular.woff2) format('woff2'),
  url(/fonts/NotoSansJP-Regular.woff) format('woff'),
  url(/fonts/NotoSansJP-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 500;
  src: url(/fonts/NotoSansJP-Medium.woff2) format('woff2'),
  url(/fonts/NotoSansJP-Medium.woff) format('woff'),
  url(/fonts/NotoSansJP-Medium.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  src: url(/fonts/NotoSansJP-Bold.woff2) format('woff2'),
  url(/fonts/NotoSansJP-Bold.woff) format('woff'),
  url(/fonts/NotoSansJP-Bold.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 900;
  src: url(/fonts/NotoSansJP-Black.woff2) format('woff2'),
  url(/fonts/NotoSansJP-Black.woff) format('woff'),
  url(/fonts/NotoSansJP-Black.otf) format('opentype');
}

@mixin fade_in($display) {

  @keyframes fade-in {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: $display;
      opacity: 0;
    }

    100% {
      display: $display;
      opacity: 1;
    }
  }

  @-moz-keyframes fade-in {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: $display;
      opacity: 0;
    }

    100% {
      display: $display;
      opacity: 1;
    }
  }

  @-webkit-keyframes fade-in {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: $display;
      opacity: 0;
    }

    100% {
      display: $display;
      opacity: 1;
    }
  }
}