//**********************************************
//* Write changeable styles here
//* Do not input real values nowhere
//*
//* ここにはstyle更可能な設定をまとめて下さい
//* 具体値は絶対に記述しないで下さい
//**********************************************

//---------------------------------------
//--- common ----------------------------
//---------------------------------------

a {
    color: $link;
}

input, select, textarea {
    border-color: $color-bold;
    &:focus {
        border-color: $color-bold;
    }
}

.c-checkbox {
    .c-checkbox__label {
        &::before {
            color: $color;
        }
    }
}

.p-search {
    .c-btn {
        background-color: $color;
        border-color: $color;
        &:hover {
            opacity:.8;
        }
    }
    hr {
        border-top: 1px solid $color !important;
    }
}

.c-raised-btn {
    &.c-raised-btn--primary {
        background-color: $color-bold !important;
        &:hover {
            opacity:.8;
        }
    }
    
}
.c-upload {
    background-color: $color-bold !important;
}

.c-checkbox {
    &__label,
    &__label--empty {
        input:not([disabled]) + & {
            &::after {
                border-color: $color-bold !important;
            }
        }
        input:not([disabled]):checked + & {
            &::after {
                border-color: $color-bold !important;
                background-color: $color-bold !important;
            }
        }
    }
}

.c-radio {
    &__label {
        input:not([disabled]) + & {
            &::before, &::after {
                border-color: $color-bold !important;
            }

            &::after {
                background-color: $color-bold !important;
            }
        }
    }
}
.c-hastag {
    background-color: $color-bold !important;
    &:hover {
        background-color: $color-light !important;
    }
}
.p-help {
     .p-help-text {
        border: 1px solid $color-bold !important;
        &::after {
            border-color: $color-bold transparent transparent transparent !important;
        }
    }
    i {
        color: $color-bold !important;
      }
}

.p-message-list {
    caption {
        background-color: $color-light !important;
    }

    td, th {
        border-color: $color-light !important;
    }

    .badge {
        color: $color-light !important;
    }
}
.u-badge {
    color: $color-bold !important;
}
.p-selection {
    ul#{&}__list {
        border-color: $color-bold !important;
    }

    &__btn {
        background-color: $color-bold !important;
        border-color:  $color-bold !important;
    }
}

.portal-button{
    background-color: $color-bold;
}

// messages
.messages__list-date {
    color: $color-bold !important;
}
.messages__more {
    background-color:$color !important
} 

//---------------------------------------
//--- header ----------------------------
//---------------------------------------

.content-header{
    &:after {
        content: "";
        display: block;
        height: 4px;
        background: linear-gradient(to right, rgb(red($header-base), green($header-base), blue($header-base)), transparent);
    }
}

//---------------------------------------
//--- side ------------------------------
//---------------------------------------
#side{
    background-color: #405066!important;
    .side-inner{
        background-color: #405066!important;
    }
}
.p-nav__item {
    &.is-active {
        color: $side-base-active-color;
    }
}

.material-icons{
    &::before {
        color: $side_base;
    }
}

.p-nav-accordion__body{
    .p-nav__item {
        &.is-active {
            color: white;
            background-color: $side_base;
        }
    }
}

.p-nav-accordion {
    &__subject {
        &::after {
            color: $side_base;
        }
        @at-root .is-opened & {
            &::after {
                color: $side_base;
            }
        }
    }
}

//---------------------------------------
//--- footer ----------------------------
//---------------------------------------

//footer {
//    background-color: $footer !important;
//}


.footer__wrap {
    .footer__sitemap{
        li{
            color: $color-bold;
        }
    }
    .footer__body--wrap {
        background-color: $color-bold;

        .footer__go-to-top {
            background-color: $color;
        }
    }
}
//---------------------------------------
//--- main ----------------------------
//---------------------------------------
