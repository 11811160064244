@import "_variable";

$color: $common__color--medium;
$color-light: $common__color--pale;
$color-bold: $common__color--thick;

$link: initial;
$header-base: rgb(red($common__color--thick), green($common__color--thick), blue($common__color--thick));
$header-dark: $color-bold;
$side-background: $color-light;
$side-base: $color-bold;
$side-base-active-color: #fff;
$side-middle: $color;
$side-middle-active-color: #b1b2b3;
$side-dark: $color-bold;
//$footer: rgb(93, 164, 221);
//$footer: $advertiser__color--medium;
$footer: $common__color--medium;

$breadcrumb: $color-bold;

@import "_changeable";
